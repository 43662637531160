import { useEffect, useState } from 'react';
import useAPI from './useAPI';

import { NASConfigurationResponse, NASConnectionResponse } from '../../shared/models/infra/NAS';
import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';

export class APINASOptions {
	mustFetchPools: boolean = false;
	mustFetchConnectionStatus: boolean = false;
	mustFetchConfiguration: boolean = false;
	asSaas: boolean = false;

	constructor(options?: Partial<APINASOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

export default function useAPINASRouterOS(nas_id: string[] | null, options?: Partial<APINASOptions>) {
	const { mustFetchConnectionStatus, mustFetchConfiguration, asSaas } = new APINASOptions(options);

	const { tenant, saasTenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, asSaas ? saasTenant || tenant : tenant);

	const [connectionStatus, setConnectionStatus] = useState<NASConnectionResponse[] | undefined>(undefined);
	const [configuration, setConfiguration] = useState<NASConfigurationResponse[] | undefined>(undefined);

	const [loadingConnectionStatus, setLoadingConnectionStatus] = useState<boolean>(false);
	const [loadingConfigurationCheck, setLoadingConfigurationCheck] = useState<boolean>(false);
	const [actionLoading, setActionLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	async function fetchConnectionStatus() {
		if (!nas_id) {
			setConnectionStatus(undefined);
			setLoadingConnectionStatus(false);
			return;
		}

		try {
			setLoadingConnectionStatus(true);
			// Convert nas_id to array if it's a single string
			const nasIds = Array.isArray(nas_id) ? nas_id : [nas_id];

			// Fetch connection status for each nas_id and progressively update state
			const promises = nasIds.map(async (id) => {
				const result = await api.get(`/infra/nas/${id}/connection`);
				setConnectionStatus((prev) => (prev ? [...prev, result] : [result]));
			});

			// Wait for all the requests to finish
			await Promise.all(promises);
		} catch (error) {
			setConnectionStatus(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingConnectionStatus(false);
		}
	}

	async function fetchConfigurations() {
		if (!nas_id) {
			setConfiguration(undefined);
			setLoadingConfigurationCheck(false);
			return;
		}

		try {
			setLoadingConfigurationCheck(true);
			// Convert nas_id to array if it's a single string
			const nasIds = Array.isArray(nas_id) ? nas_id : [nas_id];

			// Fetch configuration for each nas_id and progressively update state
			const promises = nasIds.map(async (id) => {
				const result = await api.get(`/infra/nas/${id}/configuration`);
				setConfiguration((prev) => (prev ? [...prev, result] : [result]));
			});

			// Wait for all the requests to finish
			await Promise.all(promises);
		} catch (error) {
			setConfiguration(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingConfigurationCheck(false);
		}
	}

	async function updateConfiguration(nas_id: string, ethernet: string): Promise<boolean> {
		if (!nas_id) {
			setActionLoading(false);
			return false;
		}

		try {
			setActionLoading(true);
			await api.put(`/infra/nas/${nas_id}/configuration`, { body: { ethernet } });
			setConfiguration(undefined);
			await fetchConfigurations();
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
			return false;
		} finally {
			setActionLoading(false);
		}

		return true;
	}

	function clear() {
		setConnectionStatus(undefined);
		setConfiguration(undefined);
		setLoadingConnectionStatus(false);
		setLoadingConfigurationCheck(false);
		setError(undefined);
	}

	function fetch() {
		mustFetchConnectionStatus && fetchConnectionStatus();
		mustFetchConfiguration && fetchConfigurations();
	}

	useEffect(() => {
		fetch();
	}, [nas_id, mustFetchConnectionStatus, mustFetchConfiguration]);

	return {
		connectionStatus,
		configuration,
		loadingConnectionStatus,
		loadingConfigurationCheck,
		actionLoading,
		error,
		clear,
		refresh: fetch,
		updateConfiguration,
	};
}
