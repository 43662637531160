import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCRUDOperations from './api/useCRUDOperations';

import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import { BottomControlSaveEvent } from '../slices/BottomControl';

export type BeforeSubmitType = { [key: string]: unknown };

function extractProperties(groups: Array<CRUDFormGroupType | Array<CRUDFormGroupType>>): string[] {
	return groups.flatMap((groupOrGroupArray) =>
		Array.isArray(groupOrGroupArray)
			? groupOrGroupArray.flatMap((group) =>
					group.rows.flatMap((row) => row.fields.map((field) => field.property)),
				)
			: groupOrGroupArray.rows.flatMap((row) => row.fields.map((field) => field.property)),
	);
}

function filterFormProperties<T extends object>(form: T, properties: (keyof T)[]): Partial<T> {
	const filteredForm: Partial<T> = {};
	properties.forEach((property) => {
		if (property in form) {
			filteredForm[property] = form[property];
		}
	});
	return filteredForm;
}

export function useCRUDFormPage<T>(
	endpoint: string,
	groups: Array<CRUDFormGroupType | Array<CRUDFormGroupType>>,
	createUrl: string,
	onAfterFetch?: (data: T) => object,
	onBeforeSubmit?: (payload: BeforeSubmitType, form: T) => BeforeSubmitType,
	onCreate?: (id: string) => void,
) {
	const navigate = useNavigate();
	const { id } = useParams();
	const { get, add, update, loading, error, ready } = useCRUDOperations(endpoint);

	const [form, setForm] = useState({});

	const isCreate = !id || id === 'new';

	const handleSave = async (event: BottomControlSaveEvent): Promise<boolean> => {
		const properties = extractProperties(groups);
		const payload = filterFormProperties(form, properties as (keyof typeof form)[]);
		const changedPayload = onBeforeSubmit ? onBeforeSubmit(payload, form as T) : payload;

		if (isCreate) {
			const result = await add(changedPayload);
			if (!result) return false;

			const changedData = onAfterFetch && result ? onAfterFetch(result) : result;
			setForm(changedData);

			if (event === 'save') {
				if (onCreate) onCreate(result.id);
				else navigate(createUrl.replace('/new', `/${result.id}`));
			}
			if (event === 'save/create') {
				setForm({});
			}
			return true;
		}

		const result = await update(id, changedPayload);
		if (!result) return false;

		const changedData = onAfterFetch && result ? onAfterFetch(result) : result;
		setForm(changedData);

		return true;
	};

	useEffect(() => {
		if (!id || id === 'new') {
			setForm({});
			return;
		}

		if (ready) {
			get(id).then((data) => {
				const changedData = onAfterFetch && data ? onAfterFetch(data) : data;
				setForm(changedData);
			});
		}
	}, [id, ready, get, onAfterFetch]);

	return { id, isCreate, form, setForm, handleSave, loading, error };
}
