import React, { useEffect, useState } from 'react';

import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import { useProfile } from '../hooks/useProfile';
import { RoleType, roleTypes, User } from '../shared/models/User';
import { BeforeSubmitType, useCRUDFormPage } from '../hooks/useCRUDForm';
import GenericCRUDFormPage from './GenericCRUDFormPage';
import { CRUDFormFieldType } from '../components/CRUDFormField';
import { CRUDFormSelectType } from '../components/CRUDFormSelect';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import FormGroup from '../slices/FormGroup';
import useCRUDQuery from '../hooks/api/useCRUDQuery';
import { ISP } from '../shared/models/ISP';
import { UilSpinner } from '@iconscout/react-unicons';
import Select from '../components/Select';
import { TenantRole } from '../context/ProfileContext';
import Button from '../components/Button';

const endpoint = '/management/users';
const createUrl = '/users/new';
const backwardUrl = '/users';

interface ISPRole {
	isp: ISP;
	role?: RoleType;
}

function generateHandleBeforeSubmit({
	saasAdmin,
	tenant,
	ispRoles,
}: {
	saasAdmin: boolean;
	tenant?: TenantRole;
	ispRoles?: Array<ISPRole>;
}) {
	return (payload: BeforeSubmitType, form: User): BeforeSubmitType => {
		if (saasAdmin) {
			if (!ispRoles) throw Error('ispRoles must be set when SAASAdmin');
			const ispsDict: { [key: string]: string } | undefined = ispRoles
				.filter((ispRole) => ispRole.role)
				.reduce(
					(acc, ispRole) => {
						if (ispRole.role) acc[ispRole.isp.id] = ispRole.role;
						return acc;
					},
					{} as { [key: string]: string },
				);

			payload.saas_role = form.saas_role;
			return {
				...payload,
				isps: ispsDict,
			};
		}

		if (!tenant) throw Error('tenant must be set when ISPAdmin');
		const role = payload.role;
		delete payload.isp_id;
		delete payload.role;
		return {
			...payload,
			isps: { [`${tenant.id}`]: role },
		};
	};
}

function generateOnCreate(saasAdmin: boolean, navigate: NavigateFunction) {
	return (id: string) => {
		if (saasAdmin) navigate(createUrl.replace('/new', `/${id}`));
		else navigate(backwardUrl);
	};
}

function generateHandleISPRoleChange(
	ispRole: ISPRole,
	setISPRoles: React.Dispatch<React.SetStateAction<ISPRole[]>>,
	ispRoles: ISPRole[],
) {
	return (text: string) => {
		ispRole.role = text as RoleType;
		setISPRoles([...ispRoles]);
	};
}

function generateHandleISPRoleRevoke(
	ispRole: ISPRole,
	setISPRoles: React.Dispatch<React.SetStateAction<ISPRole[]>>,
	ispRoles: ISPRole[],
) {
	return () => {
		ispRole.role = undefined;
		setISPRoles([...ispRoles]);
	};
}

function UserByIdPage() {
	const navigate = useNavigate();
	const { currentIsSaas: saasAdmin, tenant } = useProfile();
	const { response: ispResponse, loading: ispLoading } = useCRUDQuery<ISP>('/isp', {
		performFetch: saasAdmin,
		initialPageSize: 200,
	});

	const saasRoles = roleTypes
		.map((role) => ({ key: role, label: role }))
		.filter((role) => role.key.startsWith('SAAS'));
	const roles = roleTypes.map((role) => ({ key: role, label: role })).filter((role) => !role.key.startsWith('SAAS'));

	const role_field: CRUDFormSelectType = {
		property: 'role',
		label: 'Role',
		highlight: true,
		proportion: 'w-1/3',
		options: roles,
	};
	const email_field: CRUDFormFieldType = {
		property: 'email',
		label: 'Email',
		highlight: true,
		proportion: 'w-2/3',
		type: 'email',
	};
	const firstRowFields: Array<CRUDFormFieldType | CRUDFormSelectType> = [email_field, role_field];

	const given_name_field: CRUDFormFieldType = { property: 'given_name', label: 'First name', proportion: 'w-1/2' };
	const family_name_field: CRUDFormFieldType = { property: 'family_name', label: 'Last name', proportion: 'w-1/2' };

	const groups: Array<CRUDFormGroupType> = [
		{
			key: 'basic-data',
			title: 'Data',
			rows: [
				{
					key: 'row-1',
					fields: firstRowFields,
				},
				{
					key: 'row-2',
					fields: [given_name_field, family_name_field],
				},
			],
		},
	];

	const [ispRoles, setISPRoles] = useState<Array<ISPRole>>([]);
	const { isCreate, form, setForm, handleSave, loading, error } = useCRUDFormPage(
		endpoint,
		groups,
		createUrl,
		undefined,
		generateHandleBeforeSubmit({ saasAdmin, tenant, ispRoles }),
		generateOnCreate(saasAdmin, navigate),
	);

	useEffect(() => {
		const user = form as User;
		if (!user) return;
		if (!ispResponse) return;
		const isps = ispResponse.rows.map((isp) => {
			const role = user.user_isp?.find((userIsp) => userIsp.isp_id === isp.id)?.role;
			return { isp, role } as ISPRole;
		});
		setISPRoles(isps);
	}, [ispResponse]);

	if (saasAdmin) {
		const indexToRemove = firstRowFields.findIndex((item) => item === role_field);

		if (indexToRemove !== -1) {
			firstRowFields.splice(indexToRemove, 1);
		}

		email_field.proportion = 'w-full';
	}

	if (!isCreate) {
		email_field.disabled = true;
		given_name_field.disabled = true;
		family_name_field.disabled = true;
	}

	return (
		<GenericCRUDFormPage
			title="Users"
			groups={groups}
			backwardUrl={backwardUrl}
			createUrl={createUrl}
			form={form as never}
			setForm={setForm}
			loading={loading}
			error={error}
			handleSave={handleSave}
			minimalActions
		>
			{saasAdmin && (
				<div className="flex flex-col gap-4">
					{tenant?.role === 'SAASAdmin' && (
						<FormGroup title="SaaS Role">
							<div className="flex gap-2">
								<div className="w-60">
									<Select
										options={saasRoles}
										value={(form as User).saas_role || ''}
										placeholder="No role selected"
										onChange={(key) => setForm({ ...form, saas_role: key })}
									/>
								</div>
								{(form as User).saas_role && (
									<Button
										text="(revoke)"
										variant="link"
										onClick={() => setForm({ ...form, saas_role: undefined })}
									/>
								)}
							</div>
						</FormGroup>
					)}
					<FormGroup title="ISP & Roles">
						{ispLoading && <UilSpinner className="animate-spin" />}
						<div className="flex flex-col gap-2">
							<div className="grid grid-flow-row grid-cols-1 lg:grid-cols-2">
								{ispRoles &&
									ispRoles.map((ispRole) => (
										<div key={ispRole.isp.id} className="flex items-center gap-2">
											<div className="w-60">
												<Select
													options={roles}
													value={ispRole.role || ''}
													placeholder="No role selected"
													onChange={generateHandleISPRoleChange(
														ispRole,
														setISPRoles,
														ispRoles,
													)}
													full
												/>
											</div>
											<span className={`text-sm ${ispRole.role ? 'font-bold' : 'text-light'}`}>
												{ispRole.isp.name} {!ispRole.role && '(no access)'}
											</span>
											{ispRole.role && (
												<Button
													text="(revoke)"
													variant="link"
													onClick={generateHandleISPRoleRevoke(
														ispRole,
														setISPRoles,
														ispRoles,
													)}
												/>
											)}
										</div>
									))}
							</div>
						</div>
					</FormGroup>
				</div>
			)}
		</GenericCRUDFormPage>
	);
}

export default UserByIdPage;
