import React from 'react';
import { useEffect, useState } from 'react';

import WrapperPage from '../WrapperPage';
import FormGroup from '../../slices/FormGroup';
import useCRUDQuery from '../../hooks/api/useCRUDQuery';
import {
	NAS,
	NASConfigurationResponse,
	NASConnectionResponse,
	NASPPPoEServerResponse,
	NASRadiusResponse,
} from '../../shared/models/infra/NAS';
import useAPINASRouterOS from '../../hooks/api/useAPINASRouterOS';
import {
	UilCheckSquare,
	UilClock,
	UilCloudBlock,
	UilLinkBroken,
	UilNoEntry,
	UilSpinner,
} from '@iconscout/react-unicons';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Overlay from '../../components/Overlay';
import Select from '../../components/Select';

function NASConfigurationPage() {
	const [nasIds, setNASIds] = useState<Array<string> | null>(null);
	const [connectionChecked, setConnectionChecked] = useState(false);

	const { response: nas } = useCRUDQuery<NAS>('/infra/nas', { initialPageSize: 100 });
	const {
		connectionStatus,
		loadingConnectionStatus,
		configuration,
		loadingConfigurationCheck,
		error,
		updateConfiguration,
		actionLoading,
	} = useAPINASRouterOS(nasIds, {
		mustFetchConnectionStatus: !!nasIds && !connectionChecked,
		mustFetchConfiguration: connectionChecked,
	});

	useEffect(() => {
		if (!nas) return;

		if (!nasIds) {
			const nasIds = nas.rows.map((nas) => nas.id) || null;
			setNASIds(nasIds);
			return;
		}

		if (connectionStatus) {
			const nasIds = connectionStatus
				.filter((connection) => connection.connection.state)
				.map((connection) => connection.nas_id);
			setNASIds(nasIds);
			setConnectionChecked(true);
			return;
		}
	}, [nas, connectionStatus]);

	return (
		<WrapperPage title="NAS Control Panel" error={error}>
			<div className="m-4 flex flex-wrap gap-4">
				{nas?.rows.map((nas) => (
					<NASConfiguration
						key={nas.id}
						nas={nas}
						connection={connectionStatus?.find((connection) => connection.nas_id === nas.id)}
						configuration={configuration?.find((configuration) => configuration.nas_id === nas.id)}
						loadingConnectionStatus={loadingConnectionStatus}
						loadingConfigurationCheck={loadingConfigurationCheck}
						updateConfiguration={updateConfiguration}
						actionLoading={actionLoading}
					/>
				))}
			</div>
		</WrapperPage>
	);
}

function NASHeaderAction({ nas, connection }: { nas: NAS; connection?: NASConnectionResponse }) {
	return (
		<div className="flex flex-col items-end">
			<span className="text-sm text-light">{nas.api_url}</span>
			<span className="text-sm font-bold text-light">{connection?.connection.version}</span>
		</div>
	);
}

function NASConfiguration({
	nas,
	connection,
	configuration,
	loadingConfigurationCheck,
	loadingConnectionStatus,
	updateConfiguration,
	actionLoading,
}: {
	nas: NAS;
	connection?: NASConnectionResponse;
	configuration?: NASConfigurationResponse;
	loadingConnectionStatus: boolean;
	loadingConfigurationCheck: boolean;
	updateConfiguration: (nas_id: string, ethernet: string) => Promise<boolean>;
	actionLoading: boolean;
}) {
	const navigate = useNavigate();
	const [ethernet, setEthernet] = useState<string>();
	const [open, setOpen] = useState(false);

	async function handleUpdateConfiguration() {
		if (!ethernet) return;
		await updateConfiguration(nas.id, ethernet);
		setOpen(false);
		setEthernet(undefined);
	}

	return (
		<div className="w-full md:w-96 flex">
			<FormGroup
				title={nas.name}
				titleAction={{ icon: 'UilEditAlt', action: () => navigate(`/infra/nas/${nas.id}`) }}
				action={<NASHeaderAction nas={nas} connection={connection} />}
				grow
			>
				<div className="h-full flex flex-col gap-6 justify-between">
					<ul className="flex flex-col gap-2">
						<li className="pb-2 border-b border-gray-200 last:border-none">
							<NASConnection connection={connection} loading={loadingConnectionStatus} />
						</li>
						<li className="pb-2 border-b border-gray-200 last:border-none">
							<NASRadius
								connection={connection}
								radius={configuration?.radius}
								loading={loadingConnectionStatus}
							/>
						</li>
						<li className="pb-2">
							<NASPPPoEServer
								connection={connection}
								pppoeServer={configuration?.pppoe_server}
								loading={loadingConfigurationCheck}
							/>
						</li>
					</ul>
					{!loadingConnectionStatus && connection?.connection.state && !configuration?.valid && (
						<Button
							text="Fix Configurations"
							style="roundedOutline"
							variant="warning"
							icon="UilLinkBroken"
							onClick={() => setOpen(true)}
						/>
					)}
				</div>
			</FormGroup>
			{open && (
				<Overlay>
					<div className="flex flex-col items-center gap-2">
						<h2 className="font-bold text-lg">{nas.name}</h2>
						<p>Select the PPPoE-Server interface below:</p>
						<div className="w-full">
							<Select
								value={ethernet}
								onChange={(key) => setEthernet(key)}
								options={
									configuration?.pppoe_server.available_interfaces?.map((ethernet) => ({
										key: ethernet,
										label: ethernet,
									})) || []
								}
								placeholder={
									configuration?.pppoe_server.available_interfaces
										? 'Select an interface'
										: 'No interfaces found'
								}
								disabled={actionLoading}
							/>
						</div>
						<div className="mt-4 flex gap-2">
							<div className="w-32">
								<Button
									block
									text="Fix Now"
									icon={actionLoading ? { type: 'UilSpinner', spin: true } : 'UilLinkBroken'}
									variant="warning"
									onClick={handleUpdateConfiguration}
									disabled={actionLoading || !ethernet}
								/>
							</div>
							<div className="w-32">
								<Button
									block
									text="Close"
									style="outline"
									variant="light"
									onClick={() => setOpen(false)}
								/>
							</div>
						</div>
					</div>
				</Overlay>
			)}
		</div>
	);
}

function NASConnection({ connection, loading }: { connection?: NASConnectionResponse; loading: boolean }) {
	const isLoading = loading && !connection;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <UilSpinner className="text-light-gray animate-spin" />}
				{!isLoading && connection?.connection.state && <UilCheckSquare className="text-success" />}
				{!isLoading && !connection?.connection.state && <UilNoEntry className="text-warning" />}
			</div>
			<div>
				<span className="font-bold">Connection:</span>
				<div className="text-light">
					{isLoading && <span>Connecting...</span>}
					{!isLoading && connection?.connection.state && <span>Connected</span>}
					{!isLoading && !connection?.connection.state && <span>{connection?.connection.msg}</span>}
				</div>
			</div>
		</div>
	);
}

function NASRadius({
	connection,
	radius,
	loading,
}: {
	connection?: NASConnectionResponse;
	radius?: NASRadiusResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !radius;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <UilSpinner className="text-light-gray animate-spin" />}
				{!isLoading && radius?.status === 'ok' && <UilCheckSquare className="text-success" />}
				{!isLoading && connected && radius?.status !== 'ok' && <UilLinkBroken className="text-warning" />}
				{!waitingConnection && !connected && <UilCloudBlock className="text-light-extra" />}
				{waitingConnection && <UilClock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">RADIUS:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{!isLoading && radius?.status === 'ok' && <span>{radius.message}</span>}
					{!isLoading && radius && radius?.status !== 'ok' && (
						<ul className="list-disc ml-4">
							{radius.issues?.map((issue) => <li key={issue}>{issue}</li>)}
						</ul>
					)}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

function NASPPPoEServer({
	connection,
	pppoeServer,
	loading,
}: {
	connection?: NASConnectionResponse;
	pppoeServer?: NASPPPoEServerResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !pppoeServer;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <UilSpinner className="text-light-gray animate-spin" />}
				{!isLoading && pppoeServer?.status === 'ok' && <UilCheckSquare className="text-success" />}
				{!isLoading && connected && pppoeServer?.status !== 'ok' && <UilLinkBroken className="text-warning" />}
				{!waitingConnection && !connected && <UilCloudBlock className="text-light-extra" />}
				{waitingConnection && <UilClock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">PPPoE Server:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{!isLoading && pppoeServer?.status === 'ok' && <span>{pppoeServer.message}</span>}
					{!isLoading && pppoeServer && pppoeServer?.status !== 'ok' && <span>{pppoeServer.message}</span>}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

export default NASConfigurationPage;
