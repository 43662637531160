import React, { ReactNode } from 'react';
import Button from '../components/Button';
import { IconType } from '@iconscout/react-unicons';

export interface FormGroupProps {
	title?: string;
	titleAction?: FormGroupAction;
	titleAlign?: 'left' | 'center' | 'right';
	children: ReactNode;
	action?: FormGroupAction | ReactNode;
	grow?: boolean;
}

export interface FormGroupAction {
	icon: IconType;
	action: () => void;
}

function isFormGroupAction(action: FormGroupAction | ReactNode): action is FormGroupAction {
	return (action as FormGroupAction).action !== undefined;
}

function FormGroup({ title, titleAction, titleAlign = 'left', children, action, grow }: FormGroupProps) {
	return (
		<div className={`m-0 p-0 relative rounded-2xl shadow-md ${grow ? 'flex flex-col flex-grow h-full' : ''}`}>
			{title && (
				<div className="h-16 pl-4 pr-4 border-b-[1px] border-gray-200 bg-white flex items-center rounded-t-xl justify-between">
					{!titleAction ? (
						<span className={`font-bold text-md text-${titleAlign} w-2/3`} onClick={titleAction}>
							{title}
						</span>
					) : (
						<Button
							text={title}
							onClick={titleAction.action}
							variant="extraLight"
							style="roundedOutline"
							size="xs"
							icon={titleAction.icon}
						/>
					)}
					<div className="w-1/3 flex items-end justify-end">
						{action && isFormGroupAction(action) && (
							<Button icon={action.icon} style="roundedOutline" onClick={action.action} />
						)}
						{action && !isFormGroupAction(action) && action}
					</div>
				</div>
			)}
			<div className={`flex-1 p-4 bg-white ${title ? 'rounded-b-xl' : 'rounded-xl'} ${grow ? 'flex-grow' : ''}`}>
				{children}
			</div>
		</div>
	);
}

export default FormGroup;
